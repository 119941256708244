import React, { useState } from 'react';
import { Table, Modal } from 'antd';
import reviews_cn from '../../data/reviews_cn.json';
import reviews_math_us from '../../data/reviews_math_us.json';
import reviews_language_us from '../../data/reviews_language_us.json';
import reviews_science_us from '../../data/reviews_science_us.json';
import reviews_society_us from '../../data/reviews_society_us.json';
import './ReviewTable.css';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const ReviewTable = ({ subject, grade }) => {
  const { language } = useLanguage();
  const t = translations[language];

  console.log('test:', grade);
  // Get the appropriate reviews data based on language and subject
  const getReviews = () => {
    if (subject.toLowerCase() === 'math') {
      return language === 'en' ? reviews_math_us : reviews_cn;
    } else if (subject.toLowerCase() === 'language') {
      return language === 'en' ? reviews_language_us : reviews_cn;
    } else if (subject.toLowerCase() === 'science') {
      return language === 'en' ? reviews_science_us : reviews_cn;
    } else if (subject.toLowerCase() === 'society') {
      return language === 'en' ? reviews_society_us : reviews_cn;
    }
    return reviews_cn;
  };

  const reviews = getReviews();

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedContent] = useState(null);

  // Find the correct subject and grade data
  const subjectData = reviews.subjects.find(s => s.subject === subject);
  const gradeData = subjectData?.grades.find(g => g.level === grade?.toString());
  const curricula = gradeData?.curricula || [];

  if (!subjectData) {
    return <div>{t.noSubjectData} {subject}</div>;
  }

  if (!gradeData) {
    return <div>{t.noGradeData} {grade}</div>;
  }

  if (curricula.length === 0) {
    return <div>{t.noCourseInfo}</div>;
  }

  const columns = [
    {
      title: t.courseName,
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      render: (text, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: t.courseIntro,
      dataIndex: 'description',
      key: 'description',
      width: '30%',
    },
    {
      title: t.courseDetails,
      dataIndex: 'content',
      key: 'content',
      width: '55%',
      render: (text) => {
        return text;
      },
    },
  ];

  return (
    <div className="review-table">
      <Table 
        columns={columns} 
        dataSource={curricula}
        rowKey="id"
        pagination={false}
        locale={{
          emptyText: t.noCourseInfo
        }}
        style={{ width: '100%' }}
        key={`${subject}-${grade}`}
      />
      <Modal
        title={t.courseModal}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={700}
      >
        <div style={{ whiteSpace: 'pre-line' }}>
          {selectedContent}
        </div>
      </Modal>
    </div>
  );
};

export default ReviewTable; 