import React from 'react';
import BaseGradeList from './BaseGradeList';

const ScienceGradeList = () => {
  const scienceContent = {
    grades: [
      {
        id: 'kindergarten',
        name: 'Kindergarten',
        icon: 'K',
        color: '#00cccc',
        skills: 64,
        includes: [
          'Hot and cold',
          'Push and pull',
          'What do animals need to survive?',
          'Where do water lilies and saguaros live?',
          'Describe the weather'
        ]
      },
      {
        id: '1',
        name: 'First grade',
        icon: '1',
        color: '#9933cc',
        skills: 67,
        includes: [
          'Which lets light go through?',
          'How do we use light and sound to communicate?',
          'Match animals to their parents',
          'What do plants need to survive?'
        ]
      },
      {
        id: '2',
        name: 'Second grade',
        icon: '2',
        color: '#66cc00',
        skills: 82,
        includes: [
          'Identify plant parts and their functions',
          'Water on Earth',
          'Compare properties of objects',
          'Identify solids and liquids',
          'Observe and compare traits'
        ]
      },
      {
        id: '3',
        name: 'Third grade',
        icon: '3',
        color: '#ff3300',
        skills: 95,
        includes: [
          'Introduction to static electricity and charged objects',
          'Compare fossils to modern organisms',
          'Construct animal life cycle diagrams',
          'Collect and graph temperature data'
        ]
      },
      {
        id: '4',
        name: 'Fourth grade',
        icon: '4',
        color: '#6666ff',
        skills: 119,
        includes: [
          'Describe and construct flowering plant life cycles',
          'Read a topographic map',
          'Evaluate multiple design solutions to prevent flooding',
          'How do rock layers form?'
        ]
      },
      {
        id: '5',
        name: 'Fifth grade',
        icon: '5',
        color: '#ff6600',
        skills: 124,
        includes: [
          'Label parts of water cycle diagrams',
          'How does matter move in food chains?',
          'Describe and graph water on Earth',
          'Match chemical formulas to ball-and-stick models'
        ]
      }
    ]
  };

  return <BaseGradeList subject="science" {...scienceContent} />;
};

export default ScienceGradeList; 