import React from 'react';
import BaseGradeList from './BaseGradeList';

const MathGradeList = () => {
  const mathContent = {
    grades: [
      {
        id: 'pre-k',
        name: 'Pre-K',
        icon: 'P',
        color: '#ff9900',
        skills: 168,
        includes: [
          'Learn to count - up to 3',
          'Count out stickers - up to 5',
          'Above and below',
          'Classify and sort by shape',
          'Shape patterns',
          'Name the shape',
          'Pennies and nickels'
        ]
      },
      {
        id: 'kindergarten',
        name: 'Kindergarten',
        icon: 'K',
        color: '#00cccc',
        skills: 354,
        includes: [
          'Skip-count by tens',
          'Squares',
          'Subtract - numbers up to 10',
          'Make a number using addition - sums up to 5',
          'Count on the hundred chart'
        ]
      },
      {
        id: '1',
        name: 'First grade',
        icon: '1',
        color: '#9933cc',
        skills: 325,
        includes: [
          'Place value models up to 20',
          'Equal parts - 2 and 4 equal parts',
          'Addition facts - sums up to 20',
          'Subtract multiples of ten',
          'Match analog and digital clocks: to the half hour'
        ]
      },
      {
        id: '2',
        name: 'Second grade',
        icon: '2',
        color: '#66cc00',
        skills: 330,
        includes: [
          'Add and subtract numbers - up to 100',
          'Measure using an inch ruler',
          'Identify a digit up to the hundreds place',
          'Create line plots',
          'Number lines - up to 100'
        ]
      },
      {
        id: '3',
        name: 'Third grade',
        icon: '3',
        color: '#ff3300',
        skills: 361,
        includes: [
          'Multiplication facts up to 10',
          'Divide by counting equal groups',
          'Show fractions: fraction bars',
          'Create rectangles with a given area'
        ]
      },
      {
        id: '4',
        name: 'Fourth grade',
        icon: '4',
        color: '#6666ff',
        skills: 372,
        includes: [
          'Model decimals and fractions',
          'Classify triangles',
          'Multiply a 2-digit number by a 2-digit number',
          'Multi-step word problems',
          'Decompose fractions into unit fractions using models'
        ]
      },
      {
        id: '5',
        name: 'Fifth grade',
        icon: '5',
        color: '#ff6600',
        skills: 378,
        includes: [
          'Graph points on a coordinate plane',
          'Add fractions with unlike denominators',
          'Evaluate numerical expressions',
          'Volume of rectangular prisms made of unit cubes'
        ]
      }
    ]
  };

  return <BaseGradeList subject="math" {...mathContent} />;
};

export default MathGradeList; 