import React from 'react';
import SubjectNav from '../shared/SubjectNav';
import ScienceGradeList from './gradelists/ScienceGradeList';

const SciencePage = () => {
  return (
    <div className="subject-page">
      <SubjectNav />
      <div className="subject-content">
        <ScienceGradeList />
      </div>
    </div>
  );
};

export default SciencePage; 