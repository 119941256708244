import React from 'react';
import SubjectNav from '../shared/SubjectNav';
import LAGradeList from './gradelists/LAGradeList';

const LAPage = () => {
  return (
    <div className="subject-page">
      <SubjectNav />
      <div className="subject-content">
        <LAGradeList />
      </div>
    </div>
  );
};

export default LAPage; 