export const translations = {
  en: {
    // Grade Levels
    gradeLevelContent: 'Grade Level Content',
    preK: 'Pre-K',
    kindergarten: 'Kindergarten',
    firstGrade: '1st Grade',
    secondGrade: '2nd Grade',
    thirdGrade: '3rd Grade',
    fourthGrade: '4th Grade',
    fifthGrade: '5th Grade',
    skills: 'skills',
    
    // Subjects
    math: 'Math',
    languageArts: 'Language Arts',
    science: 'Science',
    socialStudies: 'Social Studies',

    // Grade Descriptions
    preKDescription: 'Counting objects, spatial concepts, size comparisons, letter names, rhyming words, etc.',
    kindergartenDescription: 'Numbers up to 20, addition and subtraction, shapes, sight words, reading comprehension, etc.',
    firstGradeDescription: 'Addition and subtraction strategies, place value, grammar, spelling patterns, reading strategies, etc.',
    secondGradeDescription: 'Place value, money, time, multiplication, reading comprehension strategies, writing process, etc.',
    thirdGradeDescription: 'Multiplication and division, fractions, area, perimeter, reading strategies, grammar, etc.',
    fourthGradeDescription: 'Multi-digit multiplication, fractions and decimals, geometry, reading comprehension, writing, etc.',
    fifthGradeDescription: 'Decimals, fractions, volume, expressions, reading analysis, writing process, etc.',
    recommendedCourses: 'Recommended Courses',

    // Subject Grid
    exploreSubjects: 'Explore Subjects',
    mathDescription: 'Comprehensive math practice for grades K-12',
    languageArtsDescription: 'Reading, writing, and language skills development',
    scienceDescription: 'Engaging science topics and experiments',
    socialStudiesDescription: 'History, geography, and civic education',

    // Review Table
    noSubjectData: 'No course information available for',
    noGradeData: 'No course information available for grade',
    noCourseInfo: 'No course information available',
    courseName: 'Course Name',
    courseIntro: 'Course Introduction',
    courseDetails: 'Course Details',
    courseModal: 'Course Details',
    homeEducation: 'Home Education',
  },
  zh: {
    // Grade Levels
    gradeLevelContent: '各年级学习内容',
    preK: '学前班',
    kindergarten: '幼儿园',
    firstGrade: '一年级',
    secondGrade: '二年级',
    thirdGrade: '三年级',
    fourthGrade: '四年级',
    fifthGrade: '五年级',
    skills: '个技能',
    
    // Subjects
    math: '数学',
    languageArts: '语文',
    science: '科学',
    socialStudies: '社会',

    // Grade Descriptions
    preKDescription: '计数物体、空间概念、大小比较、字母名称、押韵词等',
    kindergartenDescription: '20以内的数字、加减法、形状、常见字、阅读理解等',
    firstGradeDescription: '加减法策略、位值、语法、拼写模式、阅读策略等',
    secondGradeDescription: '位值、货币、时间、乘法、阅读理解策略、写作过程等',
    thirdGradeDescription: '乘除法、分数、面积、周长、阅读策略、语法等',
    fourthGradeDescription: '多位数乘法、分数和小数、几何、阅读理解、写作等',
    fifthGradeDescription: '小数、分数、体积、表达式、阅读分析、写作过程等',
    recommendedCourses: '推荐课程',

    // Subject Grid
    exploreSubjects: '探索学科',
    mathDescription: '全面的数学练习（幼儿园至12年级）',
    languageArtsDescription: '阅读、写作和语言技能发展',
    scienceDescription: '有趣的科学主题和实验',
    socialStudiesDescription: '历史、地理和公民教育',

    // Review Table
    noSubjectData: '暂无',
    noGradeData: '暂无',
    noCourseInfo: '暂无课程信息',
    courseName: '课程名称',
    courseIntro: '课程简介',
    courseDetails: '详细内容',
    courseModal: '课程详情',
    homeEducation: '家庭教育',
  }
}; 