import React from 'react';
import SubjectNav from '../shared/SubjectNav';
import MathGradeList from './gradelists/MathGradeList';

const MathPage = () => {
  return (
    <div className="subject-page">
      <SubjectNav />
      <div className="subject-content">
        <MathGradeList />
      </div>
    </div>
  );
};

export default MathPage; 