import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/layout/Header';
import Home from './components/home/Home';
import MathPage from './components/subjects/MathPage';
import LAPage from './components/subjects/LAPage';
import SciencePage from './components/subjects/SciencePage';
import SocialPage from './components/subjects/SocialPage';
import GradePage from './components/subjects/GradePage';
import { LanguageProvider } from './context/LanguageContext';

const App = () => {
  return (
    <LanguageProvider>
      <Router>
        <div className="app">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/math" element={<MathPage />} />
            <Route path="/language" element={<LAPage />} />
            <Route path="/science" element={<SciencePage />} />
            <Route path="/society" element={<SocialPage />} />
            <Route path="/:subject/:gradeId" element={<GradePage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
};

export default App;