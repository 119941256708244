import React from 'react';
import BaseGradeList from './BaseGradeList';

const SocialGradeList = () => {
  const socialContent = {
    grades: [
      {
        id: 'kindergarten',
        name: 'Kindergarten',
        icon: 'K',
        color: '#00cccc',
        skills: 58,
        includes: [
          'Jobs community helpers do',
          'Good citizenship',
          'Identify American symbols',
          'Identify human and physical features',
          'Use cardinal directions',
          'Needs and wants',
          'Goods and services',
          'Martin Luther King Jr. Day'
        ]
      },
      {
        id: '1',
        name: 'First grade',
        icon: '1',
        color: '#9933cc',
        skills: 57,
        includes: [
          'Rules and laws',
          'Government services',
          'Good citizenship',
          'Identify American landmarks and monuments',
          'Compare cities and rural areas',
          'Independence Day',
          'George Washington'
        ]
      },
      {
        id: '2',
        name: 'Second grade',
        icon: '2',
        color: '#66cc00',
        skills: 62,
        includes: [
          'The Statue of Liberty',
          'Producers and consumers',
          'Natural resources',
          'Read a letter-number grid',
          'Identify urban, suburban, and rural areas'
        ]
      },
      {
        id: '3',
        name: 'Third grade',
        icon: '3',
        color: '#ff3300',
        skills: 98,
        includes: [
          'Harriet Tubman',
          'Benjamin Franklin',
          'Sitting Bull',
          'What is economics?',
          'Identify shortage and surplus',
          'Local government',
          'Presidential elections',
          'Thanksgiving'
        ]
      },
      {
        id: '4',
        name: 'Fourth grade',
        icon: '4',
        color: '#6666ff',
        skills: 112,
        includes: [
          'State government',
          'Federal government',
          'Identify lines of latitude and longitude',
          'Identify the 50 state capitals',
          'Jamestown: the early years',
          'The Louisiana Purchase'
        ]
      },
      {
        id: '5',
        name: 'Fifth grade',
        icon: '5',
        color: '#ff6600',
        skills: 112,
        includes: [
          'Identify the 50 states',
          'Major U.S. cities',
          'Name the Thirteen Colonies',
          'The American Revolution: the Boston Tea Party',
          'The Lewis and Clark Expedition',
          'The New Deal'
        ]
      }
    ]
  };

  return <BaseGradeList subject="society" {...socialContent} />;
};

export default SocialGradeList; 