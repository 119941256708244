import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const GradeLevels = () => {
  const { language } = useLanguage();
  const t = translations[language];

  const getColorClass = (index) => {
    // Specific color for each grade level
    const colors = [
      'indigo',    // Pre-K: #4B0082
      'coral',     // Kindergarten: #FF7F50
      'teal',      // 1st Grade: #008080
      'violet',    // 2nd Grade: #8A2BE2
      'crimson',   // 3rd Grade: #DC143C
      'emerald',   // 4th Grade: #50C878
      'amber'      // 5th Grade: #FFBF00
    ];
    return colors[index];
  };

  const grades = [
    {
      level: t.preK,
      shortName: 'P',
      description: t.preKDescription,
      subjects: [
        { name: t.math, link: '/math/pre-k' },
        { name: t.languageArts, link: '/language/pre-k' },
        { name: t.science, link: '/science/pre-k' },
        { name: t.socialStudies, link: '/society/pre-k' }
      ]
    },
    {
      level: t.kindergarten,
      shortName: 'K',
      description: t.kindergartenDescription,
      subjects: [
        { name: t.math, link: '/math/kindergarten' },
        { name: t.languageArts, link: '/language/kindergarten' },
        { name: t.science, link: '/science/kindergarten' },
        { name: t.socialStudies, link: '/society/kindergarten' }
      ]
    },
    {
      level: t.firstGrade,
      shortName: '1',
      description: t.firstGradeDescription,
      subjects: [
        { name: t.math, link: '/math/1' },
        { name: t.languageArts, link: '/language/1' },
        { name: t.science, link: '/science/1' },
        { name: t.socialStudies, link: '/society/1' }
      ]
    },
    {
      level: t.secondGrade,
      shortName: '2',
      description: t.secondGradeDescription,
      subjects: [
        { name: t.math, link: '/math/2' },
        { name: t.languageArts, link: '/language/2' },
        { name: t.science, link: '/science/2' },
        { name: t.socialStudies, link: '/society/2' }
      ]
    },
    {
      level: t.thirdGrade,
      shortName: '3',
      description: t.thirdGradeDescription,
      subjects: [
        { name: t.math, link: '/math/3' },
        { name: t.languageArts, link: '/language/3' },
        { name: t.science, link: '/science/3' },
        { name: t.socialStudies, link: '/society/3' }
      ]
    },
    {
      level: t.fourthGrade,
      shortName: '4',
      description: t.fourthGradeDescription,
      subjects: [
        { name: t.math, link: '/math/4' },
        { name: t.languageArts, link: '/language/4' },
        { name: t.science, link: '/science/4' },
        { name: t.socialStudies, link: '/society/4' }
      ]
    },
    {
      level: t.fifthGrade,
      shortName: '5',
      description: t.fifthGradeDescription,
      subjects: [
        { name: t.math, link: '/math/5' },
        { name: t.languageArts, link: '/language/5' },
        { name: t.science, link: '/science/5' },
        { name: t.socialStudies, link: '/society/5' }
      ]
    }
  ];

  // Add CSS for the new layout
  const subjectGridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    width: '100%'
  };

  return (
    <section className="grade-levels">
      <h2>{t.gradeLevelContent}</h2>
      <div className="grades-container">
        {grades.map((grade, index) => {
          const colorClass = getColorClass(index);
          return (
            <div key={index} className={`grade-module ${colorClass}-bdr`}>
              <a className="grade-box-link" href={`/math/${grade.level.toLowerCase()}`} aria-label={grade.level}>
                <div className={`grade-box-tab ${colorClass}-bg`}>
                  {grade.shortName}
                </div>
                <h2 className={`grade-box-hdr ${colorClass}-text`}>
                  <span className="grade-box-short-name grade-box-name">{grade.level}</span>
                  <span className="grade-box-long-name grade-box-name">{grade.level}</span>
                  <svg className="grades-list-arrow" viewBox="0 0 9 17">
                    <polygon className={`${colorClass}-fill-svg`} points="8.7,8.5 8.7,8.5 8.7,8.5 2.3,0.4 0,2.4 4.8,8.5 0,14.6 2.3,16.6 8.7,8.5 8.7,8.5" />
                  </svg>
                </h2>
              </a>
              <div className="grade-body">
                <div className="grade-description">
                  {grade.description}
                </div>
                <ul className="list-subject-links" style={subjectGridStyle}>
                  {grade.subjects.map((subject, subIndex) => (
                    <li key={subIndex} className={`subject-link-item ${subject.name.toLowerCase().replace(' ', '-')}`}>
                      <div className="subject-skill-container">
                        <h3 className="subject-hdr">
                          <a href={subject.link} className={`subject-link ${colorClass}-text`}>
                            {subject.name}
                          </a>
                        </h3>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default GradeLevels; 