import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const SubjectNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { language } = useLanguage();
  const t = translations[language];

  const subjects = [
    { key: 'math', label: t.math, icon: '📐' },
    { key: 'language', label: t.languageArts, icon: '📚' },
    { key: 'science', label: t.science, icon: '🔬' },
    { key: 'society', label: t.socialStudies, icon: '🌎' }
  ];

  return (
    <div className="subject-nav-container">
      <nav className="subject-nav">
        {subjects.map(subject => (
          <a 
            key={subject.key} 
            href={`/${subject.key}`}
            className={`subject-nav-item ${currentPath.startsWith(`/${subject.key}`) ? 'active' : ''}`}
          >
            <span className="subject-icon">{subject.icon}</span>
            <span className="subject-name">{subject.label}</span>
          </a>
        ))}
      </nav>
    </div>
  );
};

export default SubjectNav; 