import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SubjectNav from '../shared/SubjectNav';
import ReviewTable from '../reviews/ReviewTable';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const GradePage = () => {
  const { subject, gradeId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = translations[language];
  
  console.log('URL Parameters:', { subject, gradeId });

  const getGradeName = (id) => {
    switch(id) {
      case 'pre-k':
        return t.preK;
      case 'kindergarten':
        return t.kindergarten;
      case '1':
        return t.firstGrade;
      case '2':
        return t.secondGrade;
      case '3':
        return t.thirdGrade;
      case '4':
        return t.fourthGrade;
      case '5':
        return t.fifthGrade;
      default:
        return id;
    }
  };

  const getSubjectName = (subject) => {
    switch(subject) {
      case 'language':
        return t.languageArts;
      case 'math':
        return t.math;
      case 'science':
        return t.science;
      case 'society':
        return t.socialStudies;
      default:
        return subject.toUpperCase();
    }
  };

  return (
    <div className="subject-page">
      <SubjectNav />
      <div className="subject-content">
        <div className="grade-page">
          <div className="grade-sections" style={{ gridTemplateColumns: '1fr' }}>
            <section className="grade-section">
              <div className="section-header">
                <h1>{getSubjectName(subject)} - {getGradeName(gradeId)}</h1>
                {console.log('Rendering header:', getSubjectName(subject), getGradeName(gradeId))}
                <h2>{t.recommendedCourses}</h2>
              </div>
              <ReviewTable subject={subject} grade={gradeId} />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradePage; 