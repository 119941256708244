import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const Header = () => {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <h1>Home Education</h1>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header; 