import React from 'react';
import SubjectGrid from './SubjectGrid';
import GradeLevels from './GradeLevels';

const Home = () => {
  return (
    <main>
      <SubjectGrid />
      <GradeLevels />
    </main>
  );
};

export default Home; 