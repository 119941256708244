import React from 'react';
import { useNavigate } from 'react-router-dom';

const BaseGradeList = ({ subject, title, description, grades }) => {
  const navigate = useNavigate();

  const handleGradeClick = (gradeId) => {
    navigate(`/${subject}/${gradeId}`);
  };

  return (
    <div className="grade-list">
      {title && description && (
        <div className="subject-intro">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      )}
      {grades.map(grade => (
        <div key={grade.id} className="grade-item">
          <div className="grade-header">
            <div className="grade-icon" style={{ backgroundColor: grade.color }}>
              {grade.icon}
            </div>
            <div className="grade-info">
              <h2>{grade.name}</h2>
              <div className="grade-includes">
                <span>Includes: </span>
                {grade.includes.map((item, index) => (
                  <span key={index} className="include-item">{item}</span>
                ))}
              </div>
            </div>
            <button 
              className="see-all-btn"
              onClick={() => handleGradeClick(grade.id)}
            >
              See all resources ›
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BaseGradeList; 