import React from 'react';
import BaseGradeList from './BaseGradeList';

const LAGradeList = () => {
  const laContent = {
    grades: [
      {
        id: 'pre-k',
        name: 'Pre-K',
        icon: 'P',
        color: '#ff9900',
        skills: 110,
        includes: [
          'Find the letter in the alphabet: lowercase',
          'Choose the picture that rhymes with the word',
          'Identify book parts and features',
          'Find the word that begins with a given sound'
        ]
      },
      {
        id: 'kindergarten',
        name: 'Kindergarten',
        icon: 'K',
        color: '#00cccc',
        skills: 179,
        includes: [
          'Which letter does the word start with?',
          'Read sight words: review sets 1–10',
          'Sort objects into categories',
          'Choose the short a word that matches the picture'
        ]
      },
      {
        id: '1',
        name: 'First grade',
        icon: '1',
        color: '#9933cc',
        skills: 199,
        includes: [
          'Complete the silent e words',
          'How many syllables does the word have?',
          'Order related words based on meaning',
          'Identify action verbs'
        ]
      },
      {
        id: '2',
        name: 'Second grade',
        icon: '2',
        color: '#66cc00',
        skills: 239,
        includes: [
          'Complete the two-syllable words',
          'Order events in a story',
          'Shades of meaning with pictures',
          'Sort common and proper nouns'
        ]
      },
      {
        id: '3',
        name: 'Third grade',
        icon: '3',
        color: '#ff3300',
        skills: 231,
        includes: [
          'Identify story elements',
          'Determine the meaning of words using synonyms',
          'Form and use regular and irregular plurals',
          'Use context to identify word meaning'
        ]
      },
      {
        id: '4',
        name: 'Fourth grade',
        icon: '4',
        color: '#6666ff',
        skills: 215,
        includes: [
          'Identify similes and metaphors',
          'Determine the themes of myths',
          'Use relative pronouns',
          'Form and use progressive verb tenses'
        ]
      },
      {
        id: '5',
        name: 'Fifth grade',
        icon: '5',
        color: '#ff6600',
        skills: 187,
        includes: [
          'Analyze short stories',
          'Compare and contrast stories',
          'Identify prepositions and prepositional phrases',
          'Use correlative conjunctions'
        ]
      }
    ]
  };

  return <BaseGradeList subject="language" {...laContent} />;
};

export default LAGradeList; 