import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../translations';

const SubjectGrid = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = translations[language];
  
  const subjects = [
    {
      title: t.math,
      icon: '📐',
      description: t.mathDescription,
      link: '/math'
    },
    {
      title: t.languageArts,
      icon: '📚',
      description: t.languageArtsDescription,
      link: '/language'
    },
    {
      title: t.science,
      icon: '🔬',
      description: t.scienceDescription,
      link: '/science'
    },
    {
      title: t.socialStudies,
      icon: '🌎',
      description: t.socialStudiesDescription,
      link: '/society'
    }
  ];

  const handleClick = (e, link) => {
    e.preventDefault();
    navigate(link);
  };

  return (
    <section className="subject-grid">
      <div className="subject-grid-header">
        <h2>{t.exploreSubjects}</h2>
      </div>
      <div className="grid-container">
        {subjects.map((subject, index) => (
          <a 
            key={index} 
            href={subject.link}
            className="subject-card"
            onClick={(e) => handleClick(e, subject.link)}
          >
            <div className="subject-icon">{subject.icon}</div>
            <h3>{subject.title}</h3>
            <p>{subject.description}</p>
          </a>
        ))}
      </div>
    </section>
  );
};

export default SubjectGrid; 